import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/5.png"
import Vasek from "../images/photos/vasek.jpeg"
import Vaclav from "../images/photos/vaclav.jpg"

const LECTURER = [
  {
    src: Vaclav,
    alt: "",
    personName: "Václav Ráš",
    text: [
      "Vašek pracuje s daty především na databázové úrovni. Baví ho jak datová čistota a automatizované datové transformace v BigQuery, tak složitější analýzy v Pythonu.",
      "Využívá zkušeností, které nabral ve velkých korporátech, jako jsou O2, Creditas či Česká televize, ale i u menších startupů, jako je například Qerko.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavras/",
  },
  {
    src: Vasek,
    alt: "",
    personName: "Vašek Jelen",
    text: [
      "Vašek se více než 10 let věnuje digitální analytice – od nastavování měření a vizualizace po interpretaci marketingových dat. Skrze klientské projekty a školení pomáhá firmám a mediálním agenturám mít v datech pořádek a umět je naplno využít. Jeho tech stack zahrnuje jak GA 360/GA4 360, tak Adobe Analytics a další související nástroje.",
      "Po letech na volné noze spoluzaložil analytické studio MeasureDesign, které kromě analytických projektů pro firmy a mediální agentury vzdělává nové analytiky a analytičky.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavjelen",
  },
]
const BigQueryASQL: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="BigQuery a SQL" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="BigQuery a SQL"
        numberOfLesson="5/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>úvod do BQ – vazby a práva v rámci GCP, GCP projekt, intro</div>

        <div>
          SQL
          <div>základy SQL (syntaxe, datové typy, operátory,..)</div>
          <div>specifika BQ SQL (dokumentace + changelog BigQuery)</div>
        </div>

        <div>
          GA4 a BQ
          <div>
            specifika GA4 dataset (nesting, event params vs. user properties)
          </div>
          <div>
            datové typy (jak si je pohlídat v datalayer, epn - numerical vs
            generický string, přetypování v GTM vs v BQ)
          </div>
          <div>
            use cases pro základní queries (check jestli tam něco je a kolik
            toho je)
          </div>
          <div>
            pokročilá queries
            <div>channel grouping</div>
            <div>funnely nad GA4</div>
            <div>atribuce</div>
            <div>hashovací funkce a anonymizace datasetu</div>
            <div>navigační funkce (first / last / previous / next)</div>
            <div>hledání anomálií v datech </div>
          </div>
          <div>alerty a notifikace nad GA4 datasetem (přes pub / sub)</div>
          <div>
            flatting data setu + extrakce do tabulek tak, aby se s tím dalo
            rozumně pracovat přes Keboolu a zvládli to i lidé, kteří nejsou
            weboví analytici
          </div>
        </div>
        <div>
          GCP útraty – jak je hlídat, omezit, atd.
          <div>odhadovaný výpočet v GCloud calculator</div>
          <div>Quotas → Query usage per day</div>
          <div>revize billingu</div>
          <div>
            revize nejnákladnějších queries / revize nejnákladnějších uživatelů
          </div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default BigQueryASQL
